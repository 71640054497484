<template>
  <div class="monthly">
    <HeadTop :name="name" :universityName="reportsList.universityName" />
    <!-- 1 -->
    <div
      v-if="queryData.plate.includes('1') || !queryData.plate"
      class="conter"
    >
      <div class="titles">01/&nbsp;总体情况</div>
      <div style="margin-bottom: 29px">
        <span class="ring"></span>
        <span class="situation" v-if="reportsList.courseType === 1">
          {{ reportsList.year }}年{{ reportsList.semesterName }}，{{
            reportsList.universityName
          }}
          引入向知教学服务平台视频课程，助力教师丰富教学内容，打造“线上+线下”双师课堂，实现教学模式创新。课程由
          <span
            v-for="(professor, index) in reportsList.professorCourses"
            :key="index"
          >
            {{
              professor.professorName === "向知通识课"
                ? professor.professorName
                : professor.professorName + "教授"
            }}
            <span v-if="reportsList.professorCourses.length !== index + 1"
              >、</span
            >
            <span v-else>主讲</span>
          </span>
          ，共<span style="color: #e52828"> {{ professorTotal }} </span
          >门课程，课程名称为
          <span
            v-for="(item, index) in reportsList.courseChapters"
            :key="index"
          >
            《 {{ item.courseName }} 》( {{ item.chapterCount }}讲 )
            <span v-if="reportsList.courseChapters.length !== index + 1"
              >、</span
            >
          </span>
          ，共计
          <span style="color: #e52828"> {{ Overall }} </span>讲，供
          <span style="color: #e52828">
            {{ reportsList.studentCourseCount }} </span
          >名学生学习。 具体选课情况如下：
        </span>
        <span class="situation" v-if="reportsList.courseType === 2">
          {{ reportsList.semesterNam }}，{{
            reportsList.universityName
          }}联合向知开发制作
          <span
            v-for="(item, index) in reportsList.courseChapters"
            :key="index"
          >
            《 {{ item.courseName }} 》视频课程
            <span v-if="reportsList.courseChapters.length !== index + 1"
              >、</span
            >
          </span>
          ，课程共计<span style="color: #e52828"> {{ Overall }} </span>讲，供
          <span style="color: #e52828">
            {{ reportsList.studentCourseCount }}
          </span>
          名学生学习。 具体选课情况如下：
        </span>
        <span
          class="situation"
          v-if="reportsList.courseType !== 1 && reportsList.courseType !== 2"
        >
          {{ reportsList.universityName }}联合向知开发制作
          <span v-for="(item, index) in courseChapters2" :key="index">
            《 {{ item.courseName }} 》视频课程
            <span v-if="courseChapters2.length !== index + 1">、</span>
          </span>
          ，同时引入向知教学服务平台提供的视频课程，以及向知数字化教辅工具，助力教师丰富教学内容，创新教学模式。课程由
          <span
            v-for="(professor, index) in reportsList.professorCourses"
            :key="index"
          >
            {{
              professor.professorName === "向知通识课"
                ? professor.professorName
                : professor.professorName + "教授"
            }}
            <span v-if="reportsList.professorCourses.length !== index + 1"
              >、</span
            >
            <span v-else>主讲</span>
          </span>
          ，共 {{ courseChapters1.length }} 门课程，课程名称为
          <span v-for="(item, index) in courseChapters1" :key="index">
            《 {{ item.courseName }} 》( {{ item.chapterCount }}讲 )
            <span v-if="courseChapters1.length !== index + 1">、</span>
          </span>
          ，共计<span style="color: #e52828"> {{ Overall }} </span>讲，供
          <span style="color: #e52828">
            {{ reportsList.studentCourseCount }} </span
          >名学生学习。
          <div style="margin-top: 10px">
            {{ reportsList.year }}年{{
              reportsList.semesterName
            }}的具体选课情况如下：
          </div>
        </span>
      </div>
      <el-table
        :data="tableData"
        :span-method="objectSpanMethod"
        border
        style="width: 100%; margin: 30px 0 50px; border-radius: 6px"
      >
        <el-table-column
          prop="studentCourseCount"
          label="总选课人数"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="courseName" label="课程名称"> </el-table-column>
        <el-table-column prop="studentCount" label="选课人数">
        </el-table-column>
      </el-table>
    </div>
    <!-- 2 -->
    <div
      v-if="queryData.plate.includes('2') || !queryData.plate"
      class="conter"
    >
      <div class="titles">
        0{{
          queryData.plate.includes("1") ||
          !queryData.plate ||
          queryData.plate.includes("1,2,3,4")
            ? 2
            : 1
        }}/&nbsp;学习数据
      </div>
      <div>
        <span class="ring"></span>
        <span class="situation">
          <span style="color: #e52828"
            >{{ startTime.split("-")[1] }}月{{ startTime.split("-")[2] }}日至{{
              endTime.split("-")[1]
            }}月{{ endTime.split("-")[2] }}日</span
          >，{{ reportsList.universityName }}{{ major }}学生总学习时长为
          <span style="color: #e52828"
            >{{ (reportsList.studyDuration / 60).toFixed(2) }} 小时，</span
          >
          {{ major }}每位学生平均每天学习了<span style="color: #e52828">
            {{ reportsList.studentAverageStudyChapters }} </span
          >讲课程，课后习题平均正确率为
          <span style="color: #e52828">{{ correctRate }}%</span>
          。学生保持着良好的学习进度，
          <span
            v-for="(item, index) in reportsList.coursePercents"
            :key="index"
          >
            《 {{ item.courseName }} 》的学习进度为<span style="color: #e52828">
              {{ item.percent.toFixed(2) }}%
            </span>
            <span v-if="reportsList.coursePercents.length !== index + 1"
              >、</span
            >
          </span>
          。具体课程进度如下：
        </span>
      </div>
      <div
        style="margin: 30px"
        v-for="(item, index) in reportsList.coursePercents"
        :key="index"
      >
        <el-progress
          :text-inside="true"
          :stroke-width="26"
          :percentage="item.percent.toFixed(2)"
          color="#1F7771"
          style="margin: 10px 0"
        ></el-progress>
        <div class="progressName">{{ item.courseName }}</div>
      </div>
      <div>
        <span class="ring"></span>
        <span class="situation">
          学生学习的高峰时段在
          <span
            style="color: #e52828"
            v-if="reportsList.peakTimes && reportsList.peakTimes.length"
          >
            {{ reportsList.peakTimes[0].hour }}:00 -
            {{ reportsList.peakTimes[0].hour + 1 }}:00
          </span>
          ， 充分利用碎片化学习时间，持续积累属于自己的成长。
        </span>
      </div>
      <div class="duration" style="height: 400px">
        <div style="width: auto; height: 400px" id="main1" />
      </div>
    </div>
    <!-- 3 -->
    <div
      v-if="!length && (queryData.plate.includes('3') || !queryData.plate)"
      class="conter"
    >
      <div class="titles">
        0{{
          (queryData.plate.includes("1") && !queryData.plate.includes("2")) ||
          (!queryData.plate.includes("1") && queryData.plate.includes("2"))
            ? 2
            : (queryData.plate.includes("1") &&
                queryData.plate.includes("2")) ||
              !queryData.plate ||
              queryData.plate.includes("1,2,3,4")
            ? 3
            : 1
        }}/&nbsp;学习排行
      </div>
      <div class="ranking">
        <div class="top">
          <div style="padding: 0 10px">
            <span>TOP10</span>
            <span>
              {{ startTime.split("-")[1] }}月{{ startTime.split("-")[2] }}日 -
              {{ endTime.split("-")[1] }}月{{ endTime.split("-")[2] }}日
            </span>
          </div>
          <div class="rankingName">
            <span style="color: #1f7771">排名</span>
            <span style="color: #1f7771">姓名</span>
            <span style="color: #1f7771">积分</span>
          </div>
          <div class="rankingNameFor" style="height: 45px">
            <ul style="width: 99.99%">
              <li
                v-for="(rank, index) in reportsList.studentPoints"
                :key="index"
                style="display: flex; justify-content: space-between"
              >
                <span v-if="index === 0">
                  <img src="@/assets/ReactHtmlImg/icon5.png" alt="" />
                </span>
                <span v-if="index === 1">
                  <img src="@/assets/ReactHtmlImg/icon6.png" alt="" />
                </span>
                <span v-if="index === 2">
                  <img src="@/assets/ReactHtmlImg/icon7.png" alt="" />
                </span>
                <span style="color: #1f7771" v-if="index > 2">{{
                  index + 1
                }}</span>
                <span>{{ rank.studentName }}</span>
                <span>{{ rank.point }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div
      v-if="
        reportsList.commentCount !== 0 &&
        (queryData.plate.includes('4') || !queryData.plate)
      "
      class="conter"
    >
      <div class="titles">
        0{{
          !length && (!queryData.plate || queryData.plate.includes("1,2,3,4"))
            ? 4
            : (queryData.plate.includes("1") &&
                !queryData.plate.includes("2") &&
                !queryData.plate.includes("3")) ||
              (!queryData.plate.includes("1") &&
                queryData.plate.includes("2") &&
                !queryData.plate.includes("3")) ||
              (!queryData.plate.includes("1") &&
                !queryData.plate.includes("2") &&
                queryData.plate.includes("3"))
            ? 2
            : (queryData.plate.includes("1") &&
                !queryData.plate.includes("2")) ||
              (queryData.plate.includes("1") &&
                !queryData.plate.includes("3")) ||
              (!queryData.plate.includes("1") &&
                queryData.plate.includes("2") &&
                queryData.plate.includes("3")) ||
              (queryData.plate.includes("1,2,3,4") && length)
            ? 3
            : 1
        }}/&nbsp;课程反馈
      </div>
      <p class="situation" style="margin-bottom: 30px">
        <span>
          本月
          <span style="color: #e52828"> {{ professorTotal }} </span>
          门课程共收获评论反馈
          <span style="color: #e52828">{{ reportsList.commentCount }}</span>
          条
          <span v-if="reportsList.commentCount >= 10"
            >，学生纷纷表示课程讲解详细，视频生动有趣，获益匪浅</span
          >。
        </span>
      </p>
      <el-table
        :data="reportsList.topComments"
        border
        style="width: 100%; margin: 20px 0 50px; border-radius: 6px"
      >
        <el-table-column prop="studentName" label="姓名" width="200px">
        </el-table-column>
        <el-table-column prop="content" label="课程反馈"></el-table-column>
      </el-table>
    </div>
    <Footerbottom v-if="queryData.preview" />
  </div>
</template>

<script>
import HeadTop from "@/views/Layout/headTop";
import Footerbottom from "@/views/Layout/footerBottom.vue";
import axios from "axios";
export default {
  name: "monthly",
  components: {
    HeadTop,
    Footerbottom,
  },
  data() {
    return {
      queryData: this.$router.history.current.query,
      reportsList: {},
      name: "",
      startTime: "",
      endTime: "",
      tableData: [],
      length: true,
      studentCountY: [],
      professorTotal: 0,
      plate: "",
      Overall: 0,
      courseChapters1: [],
      courseChapters2: [],
      correctRate: "",
      major: "",
    };
  },
  created() {
    this.reports();
  },
  mounted() {
    window.onresize = () => {
      this.$echarts.init(document.getElementById("main1")).resize();
    };
  },
  methods: {
    reports() {
      axios({
        method: "get",
        url: `/api/admin/stats/reports/${this.queryData.id}?msg=${this.queryData.msg}`,
      }).then((res) => {
        this.reportsList = res.data.data;
        this.name = res.data.data.name.split(
          `${res.data.data.universityName}`
        )[1];
        const zuan = res.data.data.name
          .split(`${res.data.data.semesterName}`)[1]
          .split("月份")[0];
        this.major = zuan.substring(0, zuan.length - 1);
        this.startTime = res.data.data.startTime.split("T")[0];
        this.endTime = res.data.data.endTime.split("T")[0];
        this.length = res.data.data.studentPoints.length < 10;
        this.correctRate = res.data.data.correctRate.toFixed(2);

        if (this.reportsList.courseStudents) {
          this.tableData = this.reportsList.courseStudents.map((student) => {
            return {
              ...student,
              studentCourseCount: this.reportsList.studentCourseCount,
            };
          });
        }

        if (this.reportsList.courseChapters) {
          this.professorTotal = this.reportsList.courseChapters.length;
          this.reportsList.courseChapters.map((item) => {
            return (this.Overall += Number(item.chapterCount));
          });
          this.courseChapters1 = this.reportsList.courseChapters.filter(
            (item) => item.courseType === 1
          );
          this.courseChapters2 = this.reportsList.courseChapters.filter(
            (item) => item.courseType === 2
          );
        }

        if (this.reportsList.loginTimes) {
          this.studentCountY = this.reportsList.loginTimes.map((time) => {
            return time.studentCount;
          });
        }

        this.$nextTick(() => {
          this.echartsInit();
          this.objectSpanMethod();
        });
      });
    },
    echartsInit() {
      this.$echarts.init(document.getElementById("main1")).setOption({
        color: ["#277770"],
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            {
              value: "00:00",
              textStyle: {
                fontSize: 16,
                color: "#277770",
              },
            },
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            {
              value: "12:00",
              textStyle: {
                fontSize: 16,
                color: "#277770",
              },
            },
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
            {
              value: "24:00",
              textStyle: {
                fontSize: 16,
                color: "#277770",
              },
            },
          ],
          axisLine: {
            lineStyle: {
              color: "#277770",
            },
          },
        },
        yAxis: {
          axisLine: {
            lineStyle: {
              color: "#277770",
            },
          },
        },
        series: [
          {
            type: "line",
            data: this.studentCountY,
            markPoint: {
              data: [{ type: "max", name: "Max" }],
            },
          },
        ],
      });
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 100,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
  },
};
</script>